
.calculator{
  background: whitesmoke;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  clear: both;
  float: left;
  margin-bottom: 20px;
  padding: 10px 15px;
  width: 100%;

    h2{
	    color: $baseColor;
	    font-size: 20px;
	    letter-spacing: -1px;
	    padding: 1em 0 0.5em;
    }
    .fields{
	    clear: both;
	    float: left;
	    padding: 5px 0;
	    width: 100%;
	    .label{
	    	display: inline-block;
		    float: left;
		    font-weight: bold;
		    padding: 5px 0 0 5px;
		    text-shadow: 1px 1px 0 #fff;
		    width: 100%;
		    font-size: 12px;
		    line-height: 14px;
			height: 24px;
		}
	    .button{
	    	float: right;
	    	clear: both;
	    	padding: 10px 25px;
	    	margin-top: 8px;
	    	text-decoration: none;}
	    select{
	    	width: 100%;
	    	display: block;
	    	height: auto;
	    	font-size: 13px;
	    	border: 1px solid #7a7a7a;
	    	border-radius: 0;
	    	box-shadow: none !important;
	    	outline: 0;
	    	&:hover{
	    		border-color: black;}}
    	input{
    		margin: 0;
    		height: auto;
    		padding: 4px 5px;
    		border: 1px solid #ccc;
    		border-top: 1px solid #888;
    		border-bottom: 1px solid #ccc;
    		border-radius: 3px;
    		-webkit-border-radius: 3px;
    		-moz-border-radius: 3px;
    		width: 100%;
    		background: white;
    		&:focus{
    			box-shadow: none;
    			border-bottom: 1px solid #ccc;
        	}
      	}
   	}
   	&.przenikanie-ciepla{
   		.fields{
        	&:nth-of-type(1), &:nth-of-type(4){
        		width: 47.5%;
        		clear: initial;
        	}
        	&:nth-of-type(3), &:nth-of-type(5){
				width: 47.5%;
        		clear: initial;
        		float: right;
			}
        }
   	}
   	&.paczki{
   		.fields{
        	&:nth-of-type(1){
        		width: 33%;
        		clear: initial;
        		margin-right: 3%;
        	}
        	&:nth-of-type(2){
				width: 41%;
        		clear: initial;
			}
			&:nth-of-type(3){
				width: 20%;
        		clear: initial;
        		margin-left: 3%;
			}
        }
   	}
   	&.przenikanie-fasada{
   		.fields{
        	&:nth-of-type(1), &:nth-of-type(3), &:nth-of-type(5){
        		width: 55%;
        		clear: initial;
        	}
        	&:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(6){
				width: 40%;
        		clear: initial;
        		float: right;
			}
        }
   	}
  	&.przenikalnie-dach{
  		.fields{
  			&:nth-of-type(1), &:nth-of-type(4){
        		width: 55%;
        		clear: initial;
        	}
        	&:nth-of-type(3), &:nth-of-type(6), &:nth-of-type(7){
				width: 100%;
        	}
        	&:nth-of-type(2), &:nth-of-type(5){
				width: 40%;
        		clear: initial;
        		float: right;
			}
  		}
  	}
    &.papa{
        .fields{
        	&:nth-of-type(1){
        		width: 55%;
        		clear: initial;
        	}
        	&:nth-of-type(2){
				width: 40%;
        		clear: initial;
        		float: right;
			}
        }
  	}
}

.field-result {
  	.label {
	    padding-top: 5px !important;
	    font-size: 13px !important;
	    color: #003179;
	    width: 60% !important;
	    clear: both;
	    float: none !important;
  	}
  	#result, #covResult {
	    width: 60%;
	    font-size: 22px;
	    padding: 5px 0 0 5px;
	    display: inline-block;
	    font-weight: bold;
	    color: #003179;
	    text-shadow: 2px 2px 0px white;
  	}
}

@include media-query-max($tablet) {
	.calculator {
    	.fields {
      		.label {
		        margin-bottom: 10px;
		        width: 100%;
      		}
      		select {
        		width: 100%;
      		}
      		input {
        		width: 100%;
      		}
    	}
  	}
}
