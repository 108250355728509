.map-tabs {
	position: relative;
	display: block;

	&__links, &__items {
		display: table-cell;
		vertical-align: top;
		padding: {} } }

#map {
	position: relative;
	display: block;
	.contact-map {
		position: relative;
		z-index: 5;
		&--transparent, &--number {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			z-index: 10;
			path {
				cursor: pointer; } }
		&--color {
			path {
				fill: #ccc; } }
		path.active {
			fill: $baseColor !important;
			fill-opacity: 1; } }

	svg, img {
		max-width: 100%;
		path {
			@include transition(all 100ms linear); } } }


.map-item {
	display: none;
	&__regions {
		position: relative;
		display: block;
		width: 100%;
		button {
			margin-bottom: 20px; }
		ul {
			position: relative;
			display: none;
			padding: 0 15px 20px 15px;
			font-size: 0;
			background: #fff;
			border: 1px rgba($baseColor, 0.2) solid;
			border-top: transparent;
			z-index: 999; }
		li {
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding: 3px 0;
			font-size: 12px;
			color: $baseColor; } } }
