.site-header {
  position: relative;
  display: block;
  &::after {
    content: "";
    display: block;
    margin-top: -1px;
    width: 100%;
    height: 1px;
    background: url(../images/border.gif) center center repeat; }
  &__top, &__bottom {
    position: relative;
    display: block;
    width: 100%; }
  &__top {
    padding: 15px 0;
    text-align: right;
    ul, form {
      display: inline-block;
      vertical-align: middle; }
    ul li {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      padding: 0;
      svg, img {
        display: block;
        width: auto;
        height: 20px; }
      a {
        display: block;
        padding: 0 5px;
        color: #333;
        line-height: 1.4; } }
    form {
      position: relative;
      margin-left: 10px;
      border: 0px !important;
      input {
        display: block;
        height: auto;
        margin: 0;
        padding: 5px 30px 5px 10px;
        font-size: 12px;
        background: #f5f5f5;
        border: 0px !important;
        box-shadow: none !important;
        &:focus {
          border: 0px !important; } }
      button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 3px 8px;
        margin: 0;
        border: 0px; } } }

  &__bottom {
    .row {
      margin: 0; }
    .site-logo {
      padding-right: 30px; } }

  .site-hamburger {
    display: none; } }

@include media-query-max($siteWidth) {
  .site-header {
    &__top {
      display: table;
      width: 100%;
      padding: 10px 0 0 0;
      float: none;
      > ul, > form {
        display: table-cell;
        vertical-align: middle; }
      > ul {
        text-align: left;
        li {
          p, a, span {
            font-size: 14px; }
          a {
            display: block;
            padding: 0 10px; } } }
      > form {
        input {
          padding-top: 10px;
          padding-bottom: 10px; }
        button {
          padding-top: 8px;
          padding-bottom: 8px; } } }
    &__bottom {
      .site-row {
        display: block; }
      .site-cell {
        display: block;
        width: 100%;
        &--left {
          display: table; } }
      .site-logo, .site-hamburger {
        display: table-cell;
        vertical-align: middle; }
      .site-logo {
        padding: 10px 0;
        width: 200px; }
      .site-hamburger {
        text-align: right; } } } }


@include media-query-max($tablet) {
  .site-header {
    &__top {
      > ul {
        li {
          p, a, span {
            font-size: 12px; } } } } } }

@include media-query-max($xtablet) {
  .site-header {
    &__top {
      > form {
        display: none; } }
    &__bottom {
      .site-logo {
        width: 120px; } } } }

@include media-query-max($xmobile) {
  .site-header {
    &__top {
      display: block;
      // overflow: hidden
      > ul {
        display: block;
        margin: 0 -15px;
        li {
          display: inline-block !important;
          img, svg {
            display: inline-block;
            margin: 0;
            padding: 0; }
          p, a, span {
            font-size: 10px; } } } } } }
