.sidebar-submenu {
	padding-top: 10px;
	color: #fff;
	> ul {
		display: block;
		padding: 15px 10px 15px 0;
		background: $baseColor;
		border-bottom-right-radius: 5px;
		li {
			display: block;
			margin: 0;
			padding: 0;
			a, span {
				position: relative;
				display: block;
				padding: 3px 15px;
				font-size: 12px;
				font-weight: 200;
				text-decoration: none;
				color: #fff; }

			&.submenu {
				> a {
					padding-right: 25px;
					&::after {
						content: "\f107";
						position: absolute;
						right: 15px;
						font-family: FontAwesome; } }
				ul {
					display: none; }
				&.active, &.slideDown {
					color: $baseColor;
					background: #fff;
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
					> p, > a {
						padding: 3px 10px 5px 10px;
						font-weight: 600; }
					ul {
						li {
							&:not(:last-child) {
								border-bottom: 1px #f5f5f5 solid; }
							&:hover, &.active {
								color: #000; }
							p, a {
								font-size: 10px; } } } }
				&.active {
					ul {
						display: block; } } }

			p, a {
				display: block;
				padding: 3px 10px;
				font-size: 12px;
				font-weight: 200;
				text-decoration: none;
				color: inherit; }
			p {
				font-size: 14px;
				font-weight: 600;
				opacity: 1; } } } }

