.products-subnavigation {
	position: relative;
	display: block;
	padding: 15px 0;
	border-top: 1px $baseColor dotted;
	ul {
		display: block;
		margin: 0 -10px;
		li {
			position: relative;
			display: inline-block;
			margin: 0;
			a {
				display: block;
				padding: 3px 10px;
				font-size: 11px;
				font-weight: 600;
				text-decoration: none;
				color: $baseColor;
				&:hover {
					color: #888; } }
			&:not(:last-child) {
				&::after {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					right: -2px;
					margin: auto;
					width: 2px;
					height: 2px;
					background: $baseColor;
					border-radius: 50%; } } } } }
