.gallery {
  display: block;
  width: 100%;
  margin: 30px 0;
  padding: 20px 0;
  border-top: 3px $baseColor solid;
  border-bottom: 3px $baseColor solid;
  &__images {
    display: block;
    margin: -2%;
    font-size: 0; }
  &__image {
    display: inline-block;
    vertical-align: middle;
    width: 33.3%;
    padding: 2%;
    figure {
      display: block;
      width: 100%;
      margin: 0; }
    a, span {
      display: block;
      width: 100%;
      img {
        display: block;
        width: 100%; } } } }


@include media-query-max($mobile) {
  .gallery {
    &__image {
      width: 50%; } } }
