.applications-subnavigation {
	position: relative;
	display: block;
	ul {
		display: block;
		margin: -10px;
		font-size: 0;
		li {
			display: inline-block;
			vertical-align: top;
			width: 33.33%;
			padding: 10px;
			a {
				display: block;
				font-size: 12px;
				font-weight: 600;
				text-decoration: none;
				color: $baseColor;
				@include transition(color 150ms linear);
				&:hover {
					color: #aaa;
					img {
						opacity: 0.8; } } }
			figure {
				display: block;
				width: 100%;
				margin: 0 0 5px 0;
				padding: 5px;
				border: 1px #eee solid;
				overflow: hidden; }

			img {
				display: block;
				width: 100%;
				@include transition(opacity 150ms linear);
				@include backface-visibility(hidden); } } } }



@include media-query-max(600px) {
	.applications-subnavigation {
		ul {
			li {
				width: 50%; } } } }
