.news-list {
	position: relative;
	display: block;
	.news-item {
		margin-bottom: 30px;
		padding-bottom: 30px;
		border-bottom: 1px #eee solid;
		&__content {
			display: table;
			width: 100%; }
		&__image, &__text {
			display: table-cell;
			vertical-align: top; }
		&__text {
			padding-left: 20px;
			a {
				text-decoration: none; }
			p {
				line-height: 1.8; } }

		.read-more {
			display: block;
			padding-top: 25px;
			text-align: right;
			a {
				display: inline-block;
				padding: 7px 10px 5px 10px;
				font-size: 10px;
				text-transform: uppercase;
				color: #fff;
				background: $baseColor;
				border-radius: 3px;
				@include transition(all 100ms linear);
				&:hover {
					color: $baseColor;
					background: #eee; } } } } }


.news-current {
	margin-bottom: 20px;
	padding-bottom: 0px;
	border-bottom: 1px #eee solid; }

@include media-query-max($mobile) {
	.news-list {
		.news-item {
			&__content {
				display: block;
				width: 100%; }
			&__image, &__text {
				display: block;
				padding: 0; }
			&__image {
				width: 30%;
				float: left;
				margin-right: 15px;
				img {
					width: 100%; } } } } }
