.references {
  position: relative;
  display: block;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 4px $baseColor solid;
  float: left;
  width: 100%;
  nav {
    display: block;
    margin-bottom: 30px;
    ul {
      display: block;
      margin: 0 -1px;
      font-size: 0; }
    li {
      display: inline-block;
      vertical-align: middle;
      margin: 1px;
      padding: 7px 10px 5px 10px;
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
      color: #fff;
      background: $baseColor;
      cursor: pointer;
      &.active {
        background: #333; } } }

  &__content {
    display: block;
    margin: -2%;
    font-size: 0; }

  &__reference {
    position: relative;
    display: inline-block;
    width: 29.3%;
    margin: 2%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    h4 {
      font-size: 18px;
      font-weight: 700;
      color: #fff; }
    h5 {
      font-size: 12px;
      letter-spacing: 2px;
      color: #fff; } }

  .reference {
    &__content {
      display: block;
      width: 100%;
      height: 100%;
      padding: 20px;
      background: rgba(0, 0, 0, 0.2);
      @include transition(all 200ms linear); }
    &__link {
      position: absolute;
      bottom: 20px;
      right: 20px;
      padding: 7px 15px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 2px;
      color: $baseColor;
      background: #eee;
      opacity: 0;
      @include transition(opacity 200ms linear);
      &:hover {
        color: #fff;
        background: #333; } } }


  .references__reference:hover {
    .reference__content {
      background: rgba($baseColor, 0.4); }
    .reference__link {
      opacity: 1; } }

  .ref-box {
    &__back {
      border: 0;
      color: $baseColor;
      padding: 0 10px;
      margin-bottom: 20px;
      cursor: pointer; }

    &__content {
      h2 {
        text-transform: uppercase;
        font-size: 26px {
          letter-spacing: -1px; } } }

    &__position {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 3px;
        margin-bottom: 25px;
        text-transform: uppercase;
        i {
          font-size: 1.4em;
          color: #FAB200; } }

    &__slider {
      img {
        max-width: 100%;
        height: auto; } }

    &__text {
      display: block;
      margin-bottom: 20px;

      p {
        padding: 0.5em 0; } }

    &__info {
      display: block;
      margin-bottom: 10px;
      padding-bottom: 5px;
      width: 100%;

      h5 {
        color: #464646;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase; }

      a {
        color: #003179;
        line-height: 1.7em; } } } }


@include media-query-max($xtablet) {
  .references {
    nav {
      text-align: center;
      li {
        margin: 5px;
        padding: 15px 30px; } }
    &__reference {
      width: 46%; } } }

@include media-query-max(540px) {
  .references {
    &__reference {
      width: 96%; } } }
