.mobile-menu {
	position: absolute;
	display: none;
	top: 80px;
	left: 0;
	right: 0;
	margin: auto;
	color: #fff;
	background: $baseColor;
	z-index: 99999;
	&.active {
		display: block; }
	&__tabs {
		display: block;
		margin-bottom: 20px;
		ul {
			position: relative;
			display: block;
			padding: 0 20px;
			font-size: 0;
			white-space: nowrap;
			overflow: auto;
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				height: 3px;
				background: rgba(#fff, 0.2); }
			li {
				display: inline-block;
				margin: 0;
				button {
					padding: 15px 20px;
					font-family: $secondaryFont;
					font-size: 22px;
					border: 0px;
					border-bottom: 3px transparent solid;
					&.active {
						border-color: $secondaryColor; } } } } }

	&__item {
		padding: 20px 0 0 0;
		display: none;
		&.active {
			display: block; }

		p, a {
			display: block;
			padding: 5px 10px;
			font-size: 12px;
			font-weight: 200;
			text-decoration: none;
			color: #7695c4; }
		p {
			padding-left: 20px;
			font-size: 11px;
			font-weight: 100;
			text-transform: uppercase;
			letter-spacing: 2px; }

		> ul {
			@include clearfix;
			> li {
				margin-bottom: 20px;
				padding: 0 30px;
				width: 33.3%;
				float: left; }
			> ul > li {
				position: relative;
				> a {
					position: relative;
					&::before {
						content: "\2022";
						display: inline-block;
						vertical-align: top;
						padding: 2px 5px 0 0;
						font-size: 16px;
						line-height: 1; } } }
			ul {
				padding-left: 20px; } }

		li {
			display: block;
			margin: 0;
			padding: 0;
			&.submenu {
				margin-left: -20px;
				> a {
					position: relative;
					padding-left: 30px !important;
					padding-right: 25px;
					span {
						display: none;
						position: absolute;
						top: -10px;
						right: 20px;
						width: 45px;
						height: 45px;
						font-size: 40px;
						text-align: center;
						line-height: 45px;
						color: $baseColor;
						background: #fff;
						border-radius: 50%; } }
				ul {
					display: none; }
				&.active, &.slideDown {
					> ul {
						padding: 10px 10px 10px 30px;
						background: darken($baseColor, 5%); }
					> p, > a {
						padding: 3px 10px 5px 10px;
						font-weight: 600;
						color: #fff; }
					> a > span {
						display: block; }
					ul {
						li {
							&:hover, &.active {
								color: #ddd; }
							p, a {
								font-size: 10px; } } } }
				&.active {
					ul {
						display: block; } } } }


		> li {
			margin-bottom: 20px;
			> p {
				margin-bottom: 5px; } } } }



@include media-query-max(900px) {
	.mobile-menu {
		&__item {
			> ul {
				> li {
					width: 50%; } } } } }


@include media-query-max(600px) {
	.mobile-menu {
		&__item {
			> ul {
				> li {
					width: 100%; } } } } }

@include media-query-min($siteWidth) {
	.mobile-menu {
		display: none !important; } }
