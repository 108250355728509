.product {
	position: relative;
	display: table;
	width: 100%;
	&__info, &__description {
		display: table-cell;
		vertical-align: top;
		padding: 0 20px; }
	&__info {
		padding-left: 0;
		width: 	35%;
		border-right: 1px #eee solid; }
	&__description {
		padding-right: 0; }

	.photo--border {
		width: 100%;
		margin: 0; } }



.product-tabs {
	display: block;
	width: 100%;
	margin-bottom: 30px;
	margin-left: -21px;
	border-left: 1px #fff solid;
	ul {
		display: block;
		font-size: 0;
		border-bottom: 1px #eee solid; }
	li {
		display: inline-block;
		margin: 0 0 -1px -1px;
		padding: 0;
		border: 1px transparent solid;
		&.active {
			border: 1px #eee solid;
			border-bottom: 1px #fff solid;
			button {
				color: #555; } }
		button {
			display: block;
			padding: 10px 20px;
			font-size: 12px;
			font-weight: 600;
			color: $baseColor;
			border: 0px; } } }


.product-tab {
	display: none;
	&.active {
		display: block; } }


@include media-query-max($mobile) {
	.product {
		@include display(flex);
		@include flex-direction(column-reverse);
		&__info, &__description {
			display: block;
			width: 100%; }
		&__description {
			margin-bottom: 30px;
			padding: 0; }
		&__info {
			padding: 0;
			border: none;
			.photo {
				width: auto !important;
				float: left;
				margin-right: 20px; } } } }

@include media-query-max($xmobile) {
	.product {
		&__info {
			.photo {
				width: 100% !important;
				margin: 20px 0; } } } }
