.breadcrumbs {
	position: relative;
	display: block;
	margin-bottom: -10px;
	padding: 0 20px;
	background: #eee;
	ul {
		display: block;
		margin: 0 -10px;
		padding: 5px 0; }
	li {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin: 0;
		padding: 0;
		a, span {
			display: block;
			padding: 5px 7px 5px 10px;
			font-size: 10px;
			text-decoration: none;
			color: $baseColor; }
		a:hover {
			text-decoration: underline; }
		span {
			color: #aaa; }
		&:not(:last-child) {
			&::after {
				content: "\f101";
				display: inline-block;
				vertical-align: middle;
				position: absolute;
				top: 0;
				bottom: 0;
				right: -5px;
				margin: auto;
				width: 5px;
				height: 10px;
				font-family: FontAwesome;
				font-size: 10px;
				line-height: 10px; } } } }

@include media-query-max($mobile) {
	.breadcrumbs {
		margin: 0 -20px; } }
