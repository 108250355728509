.news {
	position: relative;
	display: block;
	@include clearfix;
	&__list {
		margin: -10px; }
	&__item {
		h5 {
			font-weight: 600;
			a {
				color: $baseColor;
				&:hover {
					color: #000; } } }
		a {
			text-decoration: none; }
		img {
			max-width: 100%; }

		a.more {
			display: inline-block;
			margin-top: 20px;
			float: right;
			font-weight: 500;
			text-decoration: underline;
			&:hover {
				color: #000; } } }
	.news-item {
		padding: 10px;
		&__content {
			height: 100%;
			padding: 15px;
			background: #ccc;
			border-radius: 5px;
			@include clearfix; }
		&--large {
			.news-item__text, .news-item__image {
				display: table-cell;
				vertical-align: top; }
			.news-item__text {
				width: 60%;
				padding-right: 20px; } }
		&--small {
			width: 33.3%;
			float: left;
			h5 {
				margin-bottom: 15px;
				font-size: 12px; }
			.news-item__image {
				margin-bottom: 15px; }
			.news-item__text {
				p {
					font-size: 11px; } } } }


	.news-all-link {
		text-align: right;
		a {
			font-weight: 600;
			text-decoration: none;
			color: $baseColor; } } }

@include media-query-max($siteWidth) {
	.news {
		.news-item {
			&--large {
				width: 100%;
				.news-item__content {
					background: #fff;
					border: 4px $baseColor solid; }
				.news-item__content {
					padding: 30px 40px; }
				.news-item__image {
					img {
						width: 100%; } } } } } }

@include media-query-max($mobile) {
	.news {
		.news-item {
			&--small {
				width: 100%;
				text-align: center;
				h5 {
					font-size: 20px; }
				.news-item__text {
					padding: 0px 20px;
					p {
						font-size: 14px;
						font-weight: 400; } } } }
		.news-all-link {
			display: block;
			margin: 20px 0;
			text-align: center;
			float: none;
			@include clearfix;
			clear: both;
			a {
				display: block;
				padding: 15px 30px;
				float: none;
				font-size: 18px;
				color: #fff;
				background: $baseColor;
				border-radius: 5px; } } } }


@include media-query-max($mobile) {
	.news {
		.news-item {
			&--large {
				.news-item__content {
					padding: 15px 20px; }
				.news-item__image {
					display: block;
					width: 100%;
					padding-top: 20px;
					float: none;
					text-align: center;
					clear: both;
					img {
						display: inline-block;
						max-width: 100%;
						width: auto; } }
				.news-item__text {
					display: block;
					width: 100%; } } } } }
