a.menu-trigger {
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-left: 60px;
  width: 30px;
  height: 20px;
  &:hover {
    span.hamburger__text {
      letter-spacing: 2px; }
    span.hamburger__lines {
      &::before {
        top: -10px; }
      &::after {
        top: 10px; } } }
  span.hamburger__text {
    letter-spacing: 0px;
    @include transition(all 200ms linear); } }


a.menu-trigger span.hamburger__text {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  padding-right: 5px;
  right: 100%;
  font-size: 10px;
  text-transform: uppercase;
  color: inherit;
  line-height: 20px; }



a.menu-trigger span.hamburger__lines,
a.menu-trigger span.hamburger__lines:before,
a.menu-trigger span.hamburger__lines:after {
  @include transition(all 0.4s linear);
  background-color: $baseColor;
  display: block;
  position: absolute;
  width: 30px;
  height: 3px;
  left: 0;
  border-radius: 5px; }

a.menu-trigger span.hamburger__lines:before,
a.menu-trigger span.hamburger__lines:after {
  content: ""; }

a.menu-trigger span.hamburger__lines {
  top: 8px; }

a.menu-trigger span.hamburger__lines:before {
  @include transform-origin(33% 100%);
  top: -8px;
  z-index: 10; }

a.menu-trigger span.hamburger__lines:after {
  @include transform-origin(33% 0);
  top: 8px; }

a.menu-trigger.active span.hamburger__lines,
a.menu-trigger.active span.hamburger__lines:before,
a.menu-trigger.active span.hamburger__lines:after {
  background-color: transparent; }

a.menu-trigger.active span.hamburger__lines:before {
  top: -8px;
  @include transform(translateY(4px) translateX(1px) rotate(45deg));
  background-color: $baseColor; }


a.menu-trigger.active span.hamburger__lines:after {
  top: 8px;
  @include transform(translateY(-4px) translateX(1px) rotate(-45deg));
  background-color: $baseColor; }
