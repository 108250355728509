.logos-carousel {
	position: relative;
	display: block;
	max-width: 555px;
	margin: 50px 0;
	padding: 0 20px;
	overflow: hidden;
	.slick-arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		padding: 0;
		width: 15px;
		height: 50px;
		font-size: 0;
		text-align: center;
		color: $baseColor;
		line-height: 1;
		background: transparent !important;
		border: 0px;
		&:hover {
			&::before {
				color: #000; } }
		&::before {
			content: "";
			font-family: FontAwesome;
			font-size: 36px;
			font-weight: 600;
			line-height: 1; }
		&.slick-prev {
			left: -15px;
			&::before {
				content: "\f104"; } }
		&.slick-next {
			right: -15px;
			&::before {
				content: "\f105"; } } }

	.slick-slide {
		text-align: center;
		a {
			display: block;
			background: #f2f2f2; }
		img {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			height: 100%;
			max-height: 150px;
			left: 50%;
			margin-right: 50%;
			@include transform(translateX(-50%)); } }

	.carousel {
		.image {
			display: inline-block;
			vertical-align: middle;
			float: none;
			width: 100%;
			height: 100%;
			margin: 5px;
			overflow: hidden;
			text-align: center; } } }

@include media-query-max($siteWidth) {
	.logos-carousel {
		max-width: 650px;
		margin: 30px auto; } }

@include media-query-max($xtablet) {
	.logos-carousel {
		max-width: 400px; } }
@include media-query-max($xmobile) {
	.logos-carousel {
		display: none; } }
