.downloads {
	position: relative;
	display: block;
	&__download {
		display: block;
		margin-bottom: 3px;
		a {
			display: inline-block;
			vertical-align: middle;
			text-decoration: none;
			font-weight: 500;
			color: $baseColor;
			&:hover {
				color: #888; }
			i {
				margin-right: 5px; } } } }
