.info-modal {
  display: block;
  padding-bottom: 30px;
}
.info-modal .info-modal__content {
  display: block;
  width: 100%;
  margin-bottom: 30px;
  padding: 20px;
  border: 3px #ddd solid;
  border-radius: 5px;
  white-space: nowrap;
  box-sizing: border-box;
}
.info-modal .info-modal__content p,
.info-modal .info-modal__content .button {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  white-space: normal;
}
.info-modal .info-modal__content .button {
  float: right;
}
#info-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  z-index: 999;
}
#info-modal * {
  box-sizing: border-box;
}
#info-modal p,
#info-modal a,
#info-modal ul,
#info-modal ol {
  font-size: 14px;
  color: #00263f;
  line-height: 1.6;
}
#info-modal .info-modal__modal {
  position: relative;
  top: -100%;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 600px;
  height: 80%;
  padding: 15px;
  background: #fff;
  box-shadow: 1px 1px 10px -3px #000000;
  box-sizing: border-box;
}
#info-modal .info-modal__text {
  position: relative;
  display: block;
  width: 100%;
  height: 93%;
  padding: 10px 20px;
  box-sizing: border-box;
  clear: both;
}
#info-modal .js-close-info-modal {
  display: block;
  margin-bottom: 15px;
  float: right;
  font-size: 28px;
  color: #003179;
  cursor: pointer;
}
#info-modal .js-close-info-modal:hover {
  color: #BB6002;
}
#info-modal-text {
  position: absolute;
  top: 0;
  height: 0;
  z-index: -1000;
}


.info-modal__image{
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.info-modal__image img{
  display: block;
  width: 100%;
}