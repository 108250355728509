.site-banner {
  position: relative;
  > img {
    display: block;
    width: 100%; }
  .carousel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    overflow: hidden;
    opacity: 0;
    &.slick-initialized {
      opacity: 1; }
    .slick-list {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: auto;
      @include transform(translateY(-50%)); }
    .slick-slide {
      text-align: center;
      outline: none;
      p {
        font-size: 22px;
        font-weight: 600;
        color: #fff; } }
    .slick-arrow {
      width: 40px;
      height: 40px;
      background: rgba(#fff, 0.3);
      border-radius: 50%;
      opacity: 0;
      &.slick-prev {
        left: 0px;
        &::before {
          content: "\f104";
          font-family: FontAwesome; } }
      &.slick-next {
        right: 0px;
        &::before {
          content: "\f105";
          font-family: FontAwesome; } } } } }


@include media-query-max($xtablet) {
  .site-banner {
    .carousel {
      .slick-slide {
        p {
          font-size: 16px; } } } } }

@include media-query-max($mobile) {
  .site-banner {
    margin: 0 -20px;
    .carousel {
      .slick-slide {
        p {
          font-size: 12px; } } } } }
