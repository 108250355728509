.side-tabs {
	position: relative;
	display: block;
	&__links {
		display: table;
		table-layout: fixed;
		width: 100%;
		border: 1px $baseColor solid;
		border-top-left-radius: 5px;
		overflow: hidden;
		li {
			display: table-cell;
			vertical-align: top;
			margin: 0;
			padding: 0;
			color: $baseColor;
			background: #fff;
			button {
				display: block;
				width: 100%;
				padding: 10px 5px;
				font-size: 14px;
				font-weight: 600;
				color: inherit;
				border: 0px; }
			&.active {
				color: #fff;
				background: $baseColor; } } }
	&__items {
		display: block;
		color: #fff;
		background: $baseColor;
		border-bottom-left-radius: 5px;
		overflow: hidden; }

	&__item {
		padding: 20px 0 0 10px;
		display: none;
		&.active {
			display: block; }
		li {
			display: block;
			margin: 0;
			padding: 0;
			&.submenu {
				> a {
					padding-right: 25px;
					&::after {
						content: "\f107";
						position: absolute;
						right: 15px;
						font-family: FontAwesome; } }
				ul {
					display: none; }
				&.active, &.slideDown {
					color: $baseColor;
					background: #fff;
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
					> p, > a {
						padding: 3px 10px 5px 10px;
						font-weight: 600; }
					ul {
						li {
							&:not(:last-child) {
								border-bottom: 1px #f5f5f5 solid; }
							&:hover, &.active {
								color: #000; }
							p, a {
								font-size: 10px; } } } }
				&.active {
					ul {
						display: block; } } }

			p, a {
				display: block;
				padding: 3px 10px;
				font-size: 12px;
				font-weight: 200;
				text-decoration: none;
				color: inherit; }
			p {
				font-size: 14px;
				font-weight: 600;
				opacity: 1; } }
		> li {
			margin-bottom: 20px;
			> p {
				margin-bottom: 5px; } } } }
