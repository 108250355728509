

// colors

$main-text: #7f8c97; // main text
$link: #acb7c0; // anchor tags
$background: #e9f0f5; // body background color

$color-1: #303e49; // blue dark
$color-2: #c03b44; // red
$color-3: #f5f5f5; // white
$color-4: #75ce66; // green
$color-5: #f0ca45; // yellow


// rem fallback - credits: http://zerosixthree.se/

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// border radius

@mixin border-radius($radius:.25em) {
  border-radius: $radius;
}


// breakpoints
   
$S:     320px;   
$M:     768px;     
$L:     1170px;     

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
}




/* -------------------------------- 

Modules - reusable parts of our design

-------------------------------- */

.cd-container { /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 100%;
  max-width: $L; // breakpoints inside partials > _layout.scss
  margin: 0 auto;

  &::after { /* clearfix */
    content: '';
    display: table;
    clear: both;
  }
}



#cd-timeline {
  position: relative;
  padding: 2em 0;
  opacity: 0;
  &.show{
    opacity: 1
  }
  margin: {
    top: 2em;
    bottom: 2em;
  }

  &::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: darken($background, 5%);
  }

  @include MQ(L) {
    margin: {
      top: 3em;
      bottom: 3em;
    }

    &::before {
      left: 50%;
      margin-left: -2px;
    }
  }
}

.cd-timeline-block {
  position: absolute;
  left: 0;
  right: 0;
  margin: 2em 0;
  @include clearfix;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include MQ(L) {
    margin: 4em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.cd-timeline-year{
  position: relative;
  display: block;
  h2{
    position: relative;
    text-align: center;
    margin-bottom: 10px;
    z-index: 999;
    span{
      display: inline-block;
      padding: 2px;
      background: #fff;
    }
  }
}

.cd-timeline-marker {
  position: absolute;
  top: 0.5em;
  left: 5px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 4px darken($background, 5%);

  i {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 14px;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0%;
    margin: auto;
    text-align: center;
    line-height: 30px;
    color: darken($background, 5%);
  }

  @include MQ(L) {
    width: 30px;
    height: 30px;
    left: 50%;
    margin-left: -15px;

    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    &.is-hidden {
      visibility: hidden;
    }

    &.bounce-in {
      visibility: visible;
      @include animation(cd-bounce-1 .6s);
    }
  }
}

@include keyframes(cd-bounce-1) {
  0% {
    opacity: 0;
    @include transform(scale(.5));
  }

  60% {
    opacity: 1;
    @include transform(scale(1.2));
  }

  100% {
    @include transform(scale(1));
  }
}

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  padding: 15px 10px;
  text-align: left;
  background: $color-3;
  box-shadow: 0 3px 0 darken($background, 5%);
  border-radius: 5px;
  @include clearfix;
  .cd-block-image{
    display: block;
    margin: -10px -10px 10px -10px;
    text-align: center;
    img{
      display: inline-block;
      max-width: 100%;
    }
  }
  
  p {
    margin: 0;
    @include font-size(12px);
    line-height: 1.6;
  }

  &::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid $color-3;
  }

  @include MQ(L) {
    margin-left: 0;
    padding: 15px;
    width: 44%;

    &::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: $color-3;
    }

    .cd-timeline-block:nth-child(even) & {
      float: right;

      &::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: $color-3;
      }
    }

    &.is-hidden {
      visibility: hidden;
    }

    &.bounce-in {
      visibility: visible;
      @include animation(cd-bounce-2 .6s);
    }
  }
}

@include MQ(L) {
  /* inverse bounce effect on even content blocks */
  .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    @include animation(cd-bounce-2-inverse .6s);
  }
}

@include keyframes(cd-bounce-2) {
  0% {
    opacity: 0;
    @include transform(translateX(-100px));
  }

  60% {
    opacity: 1;
    @include transform(translateX(20px));
  }

  100% {
    @include transform(translateX(0));
  }
}

@include keyframes(cd-bounce-2-inverse) {
  0% {
    opacity: 0;
    @include transform(translateX(100px));
  }

  60% {
    opacity: 1;
    @include transform(translateX(-20px));
  }

  100% {
    @include transform(translateX(0));
  }
}




@media screen and (max-width: $L) {
  .cd-timeline-block{
    position: relative;
    top: 0 !important;
  }
  .cd-timeline-year{
    margin-bottom: 30px;
    height: auto !important;
  }
}