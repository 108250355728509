.photo {
	display: block;
	max-width: 100%;
	margin: 10px 20px 10px 20px;
	padding-bottom: 0;
	position: relative;
	z-index: 99;
	&--left {
		float: left;
		margin-left: 0px; }
	&--right {
		float: right;
		margin-right: 0px; }
	&--border {
		padding: 5px;
		border: 2px #f5f5f5 solid;
		border-radius: 5px; }
	&--center {
		margin: 10px 0;
		text-align: center;
		background: $secondaryColor;
		clear: both;
		figure {
			width: 100%;
			> p, > a {
				position: relative;
				display: block !important;
				&::before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					background: #ddd;
					z-index: 9; } }
			.fluid-image {
				width: 100%; }
			img {
				position: relative;
				display: inline-block;
				width: auto !important;
				max-width: 100%;
				z-index: 99;
				backface-visibility: hidden;
				border-radius: 6px; } } }

	&--wide {
		margin: 10px 0;
		clear: both;
		figure {
			width: 100%;
			.fluid-image {
				width: 100%; }
			img {
				width: 100%; } } }

	&--video .fluid-image {
		&::after {
			content: "\f04b" !important;
			display: inline-block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 70px;
			height: 50px;
			font-family: FontAwesome;
			font-size: 32px;
			text-align: center;
			line-height: 50px;
			color: #fff;
			opacity: 1 !important;
			background: $baseColor;
			border-radius: 10px;
			z-index: 9999; } }

	figure {
		display: table;
		table-layout: fixed;
		max-width: 100%;
		position: relative;
		margin: auto;
		line-height: 1;
		font-size: 0;
		img {
			width: 100%;
			backface-visibility: hidden {
      -webkit-backface-visibility: hidden;
      @include transform(translateZ(0)); } }
		figcaption {
			display: table-caption;
			caption-side: bottom;
			padding: 5px 0 5px 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(255, 255, 255, 0.9);
			font-size: 10px;
			text-align: center;
			@include transition(all 200ms linear);
			p, a, span {
				font-size: 14px;
				font-weight: 300;
				color: $baseColor; } }


		.fluid-image {
			display: block;
			text-align: center;
			border-radius: 6px;
			overflow: hidden;
			border: 1px $baseColor solid;
			img, iframe {
				@include transition(opacity 300ms linear);


				// img
 } } }				// 	opacity: 0.7

	&:not(.photo--video) {
		figure {
			.fluid-image {
				&::after {
					content: "\f065";
					font-family: FontAwesome;
					opacity: 0;
					@include transition(opacity 300ms linear); } }
			&:hover {
				.fluid-image {
					&::after {
						content: "\f065";
						font-family: FontAwesome;
						font-size: 26px;
						position: absolute;
						top: 5px;
						right: 5px;
						margin: auto;
						padding: 12px;
						width: 50px;
						height: 50px;
						color: $baseColor;
						background: rgba(255,255,255, 0.8);
						box-shadow: 3px 3px 5px -3px #000;
						z-index: 999;
						line-height: 1;
						opacity: 1; }
					+ figcaption {
						top: 100%; } } } } } }



@include media-query-max($xmobile) {
	.photo {
		width: 100% !important; } }
