button {
	display: inline-block;
	padding: 5px 15px;
	background: transparent;
	border: 2px $baseColor solid;
	&:focus {
		background: transparent; } }
.button {
	display: inline-block;
	padding: 10px 15px;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	background: $baseColor;
	border-radius: 6px;
	@include transition(all 200ms linear);
	&:hover {
		color: #fff;
		background: lighten($baseColor, 5%);
		border-color: lighten($baseColor, 5%); }
	&:focus {
		background: $baseColor; } }
