.side-links {
  position: relative;
  display: block;
  &.mobile-show {
    text-align: center; }
  &__link {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 170px;
    height: 90px;
    margin-bottom: 15px;
    text-align: left;
    text-decoration: none;
    &:first-child {
      border-bottom: 2px $thirdColor solid;
      span {
        color: #333;
        padding-right: 0px; } }
    span {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 5px 10px;
      font-weight: 600;
      color: #fff;
      opacity: 1;
      @include transition(opacity 150ms linear); } }

  &__link:hover {
    span {
      opacity: 0.3; } } }
