@import "partials/layout";
@import "partials/header";
@import "partials/footer";

@import "widgets/pagination";
@import "widgets/button";
@import "widgets/side-links";
@import "widgets/products-subnavigation";
@import "widgets/applications-subnavigation";
@import "widgets/hamburger";

@import "sections/side-tabs";
@import "sections/site-nav";
@import "sections/news";
@import "sections/site-banner";
@import "sections/logos-carousel";
@import "sections/breadcrumbs";
@import "sections/product";
@import "sections/map";
@import "sections/sidebar-submenu";
@import "sections/references";
@import "sections/news-list";
@import "sections/mobile-menu";
@import "sections/calculator";
@import "sections/timeline";
@import "sections/info-modal";
