html, body {
  position: relative;
  display: block;
  min-height: 100%;
  font-family: $baseFont;
  color: $fontBaseColor;
  box-sizing: border-box;
  *, *::after, *::before {
    box-sizing: border-box; } }

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.75em 0;
  font-family: 'Humanist', sans-serif;
  font-weight: 400;
  color: $baseColor;
  line-height: 1.2;
  a {
    font-size: inherit; } }
h1 {
  font-size: 32px; }
h2 {
  font-size: 28px; }
h3 {
  font-size: 24px;
  line-height: 1.3; }
h4 {
  font-size: 20px;
  line-height: 1.4; }
h5 {
  margin-bottom: 0.4em;
  font-size: 16px;
  line-height: 1.5; }

p, a, ul, ol {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 1.4; }


ul, ol {
  list-style-position: inside; }


table {
  width: 100%;
  margin-bottom: 1em;
  text-align: left;
  border-bottom: 2px $baseColor solid;
  thead {
    color: #fff;
    background: $baseColor;
    th, td {
      padding: 10px 15px;
      font-size: 12px; } }
  tbody {
    tr {
      &:nth-child(even) {
        background: #f5f5f5; } }
    td {
      padding: 10px 15px;
      font-size: 12px; } } }



input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea.materialize-textarea {
  box-sizing: border-box; }


.text {
  p, a,ul, ol {
    line-height: 1.8em; }
  ul, ol {
    padding-left: 15px;
    list-style-position: outside; } }

#site {
  @include display(flex);
  @include flex-direction(column);
  @include align-items(stretch);
  min-height: 100vh;
  .site-content {
    position: relative;
    @include flex(1);
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      height: 10px;
      background: #eee;
      top: 41px; } } }

.site-row {
  display: table;
  width: 100%; }
.site-cell {
  display: table-cell;
  vertical-align: top;
  &--left {
    width: 25%; }
  &--right {
    width: 75%; } }

.sidebar {
  padding-bottom: 50px;
  &-widget {
    display: block;
    padding: 15px 0 0 0;
    &:not(:last-child) {
      padding-bottom: 15px;
      border-bottom: 1px #ddd solid; }
    p, a, ul, ol {
      font-size: 11px; }
    strong {
      color: $baseColor; } } }

.site-inner {
  padding: 30px 20px 20px 20px;
  .site--default & {
    padding-right: 0; } }
.optional-sidebar, .sidebar-submenu {
  width: 170px;
  padding: 25px 0; }


.section-title {
  font-weight: 600;
  color: $baseColor; }


section + section {
  margin: 10px 0; }

.site-wrapper {
  @include wrapper($siteWidth); }

.mobile-show {
  display: none !important; }


.mobile-side-info {
  padding: 20px 30px;
  @include clearfix;
  > div {
    display: block;
    float: left;
    width: 50%;
    border: 0px !important; } }

@include media-query-max($siteWidth) {
  .mobile-show {
    display: block !important; }
  .mobile-hide {
    display: none !important; }
  .site-wrapper {
    max-width: 800px; }
  .site-inner {
    padding: 30px 0; } }

@include media-query-max($xtablet) {
  .site-content {
    &::before {
      display: none; } }
  .site-inner {
    padding: 20px 0 30px 0; } }

@include media-query-max(600px) {
  .col {
    margin-bottom: 30px; } }
