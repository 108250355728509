.site-nav {
	display: block;
	width: 100%;
	padding: 0 20px;
	background: $secondaryColor;
	ul {
		display: block;
		margin: 0 -10px;
		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0;
			padding: 0;
			a, p {
				display: block;
				padding: 12px 10px 13px 10px;
				font-size: 12px;
				font-weight: 600;
				text-decoration: none;
				color: #000; }
			a {
				@include transition(color 150ms linear); }
			a:hover {
				color: #fff; } } } }

@include media-query-max($xtablet) {
  .site-nav {
    display: none; } }
