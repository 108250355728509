.site-footer {
	position: relative;
	display: block;
	padding-top: 30px;
	border-top: 1px $baseColor dotted;
	&__top, &__middle, &__bottom {
		display: block;
		width: 100%; }

	&__top {
		margin-bottom: 30px; }

	&__middle {
		padding: 10px 0;
		background: $secondaryColor;
		ul {
			margin: 0 -10px;
			li {
				display: inline-block;
				vertical-align: middle;
				margin: 0;
				padding: 0;
				a {
					display: block;
					padding: 5px 10px;
					text-decoration: none;
					color: #000;
					&:hover {
						color: #555; } } } } }
	&__bottom {
		padding: 15px 0;
		.sf__col {
			&:nth-child(even) {
				text-align: right; }
			img, svg {
				display: inline-block;
				vertical-align: middle;
				width: 80px;
				height: auto;
				margin-left: 5px; } } } }



.sf__cols {
	display: table;
	table-layout: fixed;
	margin: 0 -20px; }
.sf__col {
	display: table-cell;
	vertical-align: top;
	width: 20%;
	padding: 0 20px; }


@include media-query-max($siteWidth) {
	.site-footer {
		&__top {
			margin-bottom: 0;
			.sf__cols {
				display: block;
				font-size: 0;
				text-align: center; }
			.sf__col {
				display: inline-block;
				width: 33.3%;
				margin-bottom: 40px; } } } }

@include media-query-max($xtablet) {
	.site-footer {
		&__top {
			margin-bottom: 0; }
		&__middle {
			display: none; }
		&__bottom {
			padding: 20px 0;
			border-top: 2px $baseColor solid;
			.sf__cols {
				display: block;
				width: 100%; }
			.sf__col {
				display: block;
				width: 100%;
				text-align: center !important;
				&:not(:last-child) {
					margin-bottom: 15px; } } } } }

@include media-query-max($xmobile) {
	.site-footer {
		&__top {
			.sf__col {
				width: 50%; } } } }
